import React, { useMemo, useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { EtsyFeatured } from "../components/etsy"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import sizeMe from "react-sizeme"

const responsive = {
  0: { items: 1 },
  300: { items: 1 },
  600: { items: 2 },
  900: { items: 3 },
  1200: { items: 4 },
  1500: { items: 5 },
  1800: { items: 6 },
}

const Index = ({ data, location, size }) => {
  const siteTitle = data.site.siteMetadata.title
  const images = data.carouselImgs.edges
  const carousel = useMemo(() => {
    if (images) {
      const shuffled = images
        .map(v => ({ v, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ v }) => v)
      return shuffled.map(img => (
        <Img
          className="carousel-img"
          key={img.node.fields.slug}
          fluid={img.node.frontmatter.carouselImage.childImageSharp.fluid}
        />
      ))
    }
    return null
  }, [images])

  const [startIndex, updateStartIndex] = useState(0)

  const slideChanged = evt => {
    updateStartIndex(evt.item)
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <AliceCarousel
        dotsDisabled={true}
        buttonsDisabled={true}
        keysControlDisabled={true}
        responsive={responsive}
        autoHeight={false}
        autoPlay={true}
        autoPlayInterval={4000}
        startIndex={startIndex}
        onSlideChanged={slideChanged}
        // shouldHandleResizeEvent //={() => null}
        items={carousel}
      />
      {size.width >= 800 ? (
        <EtsyFeatured size={size} />
      ) : (
        <div style={{ margin: 13 }} />
      )}
    </Layout>
  )
}

export default sizeMe()(Index)

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    carouselImgs: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/carousel-/" } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            order
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

import React from "react"
import { Link } from "gatsby"
import "../css/layout.css"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEtsy } from "@fortawesome/free-brands-svg-icons"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import logo from "../img/decaf1.png"

export const Figure = styled.figure`
  display: block;
  box-shadow: 10px 10px 5px #888888;
  background-color: #fff;
  margin: 0;
  > img {
    max-width: 100%;
  }
  > a {
    > img {
      max-width: 100%;
    }
  }
  > figcaption {
    display: block;
    margin: 0.2em 0 0;
    font-weight: normal;
    font-size: 12px;
    padding: 3px;
  }
`

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 3px rgba(1, 1, 1, 0.5);
  max-width: 1200px;
  width: 86%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
`

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Loading = text => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 30,
        maxHeight: "30px",
      }}
    >
      <FontAwesomeIcon icon={faCircleNotch} className="spin-it" />
      <p style={{ color: "#d853ae" }}>{text.text}</p>
    </span>
  )
}

export const ArticleHeader = title => {
  return (
    <>
      <hr />
      <h1 style={{ margin: 0 }}>{title.title}</h1>
      <hr />
    </>
  )
}
const Menu = () => {
  const pages = [
    { url: `/`, title: `Home` },
    { url: `/commissions`, title: `Commissions` },
    { url: `/shop`, title: `Shop` },
    { url: `/videos`, title: `Videos` },
    { url: `/contact`, title: `Contact` },
  ]
  return (
    <ul>
      {pages.map(page => (
        <li key={page.url}>
          <Link to={page.url}>{page.title}</Link>
        </li>
      ))}
    </ul>
  )
}
// const Main = styled.main `
//   font-size: 14px;
//   flex-grow: 1;
//   flex-shrink: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width:100%;
// `
const CopyWrite = () => {
  const year = new Date().getFullYear()
  return <p className="copy-write">&copy; {year} Decaffeinated Designs</p>
}
const Layout = ({ location, children }) => {
  return (
    <div className="flex-layout">
      <header className="header">
        <a className="header-link" href="/">
          <img
            className="header-img"
            src={logo}
            alt="Decaffeinated Designs Logo"
          />
        </a>
        <div className="header-text">
          <span className="site-title">Decaffeinated Designs</span>
          <span className="site-sub-title">
            Illustrating Dreams one cup at a time
          </span>
        </div>
      </header>
      <nav className="nav">
        <Menu location={location} />
      </nav>
      <main className="main">{children}</main>
      <footer className="footer">
        <div className="footer-content-outer">
          <div className="footer-content-inner">
            <nav className="bottom-nav">
              <Menu location={location} />
            </nav>
            <div className="shop-foot">
              <p>
                Visit our{" "}
                <a
                  className="etsyLink m-0 p-0"
                  href="https://www.etsy.com/shop/DecaffeinatedDesigns"
                >
                  Etsy Shop
                </a>
              </p>
              <p className="title">
                <strong>Available locally in:</strong>
              </p>
              <p className="places">Juneau, Alaska</p>
            </div>
            <CopyWrite />
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
